

.main{
    background-color: none;
    margin-top: 100px;
}

.main .boxes{
    min-height: 200px;
    width: 100%;
    margin-top: 50px;
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
    flex-wrap: wrap;
}

.main .boxes .box .part_txt{
    padding: 10px;
    font-size: 15px;
    width: fit-content;
}

.main .boxes .box .part_txt h3{
    color: #3f3d56;
    width: fit-content !important;
    padding: 0 0 20px 0;
}

.main .boxes .box .part_txt ul{
    width: 100%;
    height: fit-content;
}

.main .boxes .box .part_txt ul li{
    width: fit-content;
    display: inline-block;
    margin-right: 10px;
    
}

.main .boxes .box .part_txt ul li{
    padding: 2px 0px;
    margin-left: 0px;
    color: #54545e;
    font-weight: 500;
    /* background: #ffffff63; */
    /* border: 1px solid #ddd; */
    border-radius: 20px;
    font-size: 14px;
}