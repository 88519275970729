.loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f3f3f3;
  }
  
  .spinner {
    border: 7px solid #f3f3f3; /* Light grey */
    border-top: 7px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 70px;
    height: 70px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  .loading-container p {
    font-size: 1.2em;
    margin-top: 20px;
    color: #333;
  }
  