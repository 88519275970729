.area_ba{
    width: 100%;
    min-height: 700px;
    /* background-color: lightpink; */
    margin-top: 100px;
    margin-bottom: 50px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.area_ba .blogarea{
    width: 70%;
    min-height: 700px;
    /* border: 2px solid darkred; */
    background-color: #ffffff;
    padding: 50px;
}

.area_ba .adarea{
    display: none;
    flex: 2;
    width: 70%;
    min-height: 700px;
    /* border: 2px solid darkred; */
}

.blogarea ul li{
    display: inline-block;
    font-size: 15px;
    margin-right: 30px;
    padding: 10px 0;
}

.blogarea .cover{
    width: 100%;
    height: 280px;
    min-height: 30vh;
    background-color: #000000;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

.blogarea .cover img{
    width: 100%;
    object-fit: cover;
}

.blogarea .maintxt{
    /* border: 2px solid yellow; */
    width: 100%;
    min-height: 500px;
    margin-top: 20px;
}

.maintxt p{
    padding: 10px;
    font-size: 17px;
}

.maintxt img{
    display: block;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 20px;
    max-width: 60%;
    border: 5px solid lightblue;
}

.adarea{
    margin: 30px;
    padding: 20px;
    background-color: lightblue;
}

@media (max-width: 768px) {
    .area_ba{
        flex-direction: column;
    }

    .area_ba .blogarea{
        order: 2;
        width: 100%;
    }

    .area_ba .adarea{
        flex: 2;
        width: 100%;
        margin: 30px 0;
        padding: 20px;
    }

    .blogarea .cover{
        width: 100%;
        height: 280px;
        height: 20vh !important;
        background-color: #636363;
    }

    .blogarea .cover img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .maintxt img{
        display: block;
        margin: auto;
        margin-top: 20px;
        margin-bottom: 20px;
        max-width: 80%;
        border: 5px solid lightblue;
    }
}

@media (max-width: 500px) {

    .blogarea h1{
        font-size: 25px;
    }

    .blogarea h2{
        font-size: 20px;
    }

    .blogarea h3{
        font-size: 18px;
    }

    .blogarea ul li{
        display: inline-block;
        font-size: 12px;
        margin-right: 30px;
        padding: 10px 0;
    }

}