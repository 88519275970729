footer{
    min-height: 60px;
    width: 100%;
    background-color: #20649b;
    color: #ebf6ff;
    line-height: 1.5;
}

footer p{
    padding: 20px 0;
    text-align: center;
}

footer a{
    color: #20649b;
    text-decoration: underline;
}
