/* global */
*{
    margin: 0;
    padding: 0;
    scroll-behavior: smooth;
    list-style: none;
    text-decoration: none;
    box-sizing: border-box;
}

.container{
    max-width: 1270px;
    height: 100%;
    width: 90%;
    box-sizing: border-box;
    margin: 0 auto;
}

ul, a{
    margin: 0;
    padding: 0;
}

/*global */

body{
    background-color: #EDEDED;
    font-family: arial;
    min-width: 400px;
}




/* .test{
    width: 1519px;
    height: 768px;
    position: absolute;
    background-color: red;
    top: 0;
} */

section{
    min-height: 700px;
    width: 100%;
    border-bottom: 2px solid rgba(85, 85, 85, 0);
}

section h1{
    text-align: center;
    padding: 20px;
}








/* media queries */

@media screen and (max-width: 1330px){
    .hero .rightside{
        display: none;
    }

    .hero .top{  
        height: 40vh;
        width: 50%;
        float: none;
        background: blue;
        /* background: url('./Assets/images/dog-illu.svg'); */
        background-position: center center ;
        background-size: 100%;
        background-repeat: no-repeat;
        background-color: none;
        background-color: transparent;
        margin: auto;
    }

    .hero .leftside{
        width: 100%;
        height: 50%;
        float: none;
        background-color: none;
        margin-bottom: 50px;
    }

    .hero .leftside h1{
        padding-top: 0px;
        font-size: 40px;
        text-align: center;
    }

    .hero .searchbar{
        width: 70%;
        margin: 0 auto;
        float: none;
    }

    .hero .searchbar input{
        float: none;
        margin: auto;
    }

    .hero .searchbar .search_icon{
        float: none;
        margin: auto;
    }

    .main .nav{
        display: block;
        height: auto;
        min-height: 100px;
    }
    
    .main ul li{
        display: inline-block;
    }
    
    .main ul li a{
        padding: 10px 20px;
        float: left;
        outline: 1px solid #ddd;
        background-color: #f1f1f1;
        margin: 5px;
        color: #666666;
    }
    
    .main ul li a.active{
        font-weight: 500;
        background-color: #2480cc;
        color: #fff;
    }
    
    .main ul li a:hover{
        font-weight: 500;
        background-color: #2480cc;
        color: #fff;
    }
}

@media screen and (max-width: 1031px){
    
    /* header .button{
        display: flex;
        margin-right: 10px;
    } */
    /* header #web{
        display: none;
    }

    header .m{
        display: none;
        position: relative;
        float: none;
        background-color: #ffffff;
        margin-top: 0px;
        width: 100%;
        height: 100vh;
    }
    
    header .m li{
        display: block;
        width: 100%;
        margin: auto;
    
    }
    
    header .m li a{
        display: block;
        color: #999999;
        padding: 0px 50px;
        background-color: rgb(230, 230, 230);
        margin-bottom: 5px;
        font-size: 1.2rem;
        font-weight: 400;
        transition: 0.20s all ease-in;
        text-align: left;
        width: 100%;
    }
    
    header .m ul li a:hover{
        background-color: #2480cc;
        text-decoration: none;
        box-sizing: content-box;
        color: white;
    } */

    .hero .leftside h1 {
        padding-top: 0px;
        font-size: 36px;
        text-align: center;
        width: 100%;
    }

    .hero .searchbar{
        margin: 30px 0;
        width: 100%;
    }

    .main .nav{
        padding-top: 50px;
    }

    .main .boxes{
        min-height: 400px;
        width: 100%;
        margin-top: 50px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-wrap: wrap;
        flex-direction: row;
    }
}

@media screen and (max-width: 768px) {
    .container{
        width: 90%;
    }
}