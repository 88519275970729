.box{
    min-height: 200px;
    width: 300px;
    background-color: #f1f1f1;
    border: 1px solid #ddd;
    float: none;
    margin-bottom: 50px;
    transition: 0.15s all ease-out;
    z-index: 0;
}

.box:hover{
    transform: scale(1.05);
    box-shadow: 0px 0px 10px #2480cc;
}

.box .part_img{
    height: 150px;
    width: 100%;
    background-color: #444;
    /* object-fit: cover; */
    overflow: hidden;
}

.box .part_img img{
    width: 100%;
    object-fit: cover;
}