header{
    position: fixed;
    top: 0;
    z-index: 10px;
    height: 63px;
    width: 100%;
    -webkit-transition: all ease-out 0.15s;
    -moz-transition: all ease-out 0.15s;
    -o-transition: all ease-out 0.15s;
    transition: all ease-out 0.15s;
    background-color: #fff;
    z-index: 10;
}

header .searchbar{
    margin: auto;
    height: 63px;
    width: 50%;
    margin-left: 2vw;
    background-color: rgba(255, 0, 0, 0);
    display: none;
    justify-content: left;
    align-items: center;
    float: left;
    background-color: transparent;
    -webkit-transition: all ease-out 0.15s;
    -moz-transition: all ease-out 0.15s;
    -o-transition: all ease-out 0.15s;
    transition: all ease-out 0.15s;
}



header .searchbar input{
    padding: 6px 0px 6px 5px;
    width: 100%;
    float: left;
    color: #616161;
    font-size: 16px;
    border: 2px solid #ddd;
    background-color: rgba(241, 241, 241, 0.911);
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.151);
    border-radius: 3px 0 0 3px;
}

header .searchbar input:focus{
    outline: none;
}

header .searchbar .search_icon{
    float: left;
    height: 35px;
    width: 50px;
    background-color: #2480cc;
    border: none;
    border-radius: 0px 3px 3px 0px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.1s all;
    font-size: 18px;
    color: #daeeff;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.151);
}

header .searchbar .search_icon:hover{
    transform: scale(1.04);
    color: #fff;
}

header .searchbar .search_icon:active{
    transform: scale(1.0);
}

.change_color {
    position: fixed;
    background-color: rgba(255, 255, 255, 0.952);
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.151);
}

.change_color .searchbar{
    display: flex;
}

header .brand{
    float: left;
    height: 100%;
    line-height: 63px;
    color: #2480cc;
    font-size: 1.5rem;
    font-weight: 600;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.2rem;
}

header .brand img{
    height: 60%;
    border-radius: 10%;
    margin-top: -5px;
}

header .m{
    display: none;
}

header .button{
    position: relative;
    height: 20px;
    width: 50px;
    background-color: none;
    float: right;
    right: 0;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: 20px;
    cursor: pointer;
    display: none;
    padding: 0 5px;
}

header .button span{
    position: relative;
    display: block;
    height: 18%;
    padding: 1px;
    width: 100%;
    background: #2480cc;

}

header .button .s2{
    width: 80% !important;
}

header .button .s3{
    width: 60% !important;
}

header ul{
    float: right;
    line-height: 63px;
}

header ul li{
    display: inline-block;
    line-height: 63px;
}

header ul li a{
    padding: 10px 20px;
    /* background-color: #9c9c9c; */
    color: #3f3d56;
}

header ul li a:hover{
    background-color: #2480cc;
    color: #fff;
    border-radius: 5px;
}

header ul li a.active{
    background-color: #2480cc;
    color: #fff;
}

@media (max-width: 768px) {
    header ul{
        display: none;
    }

    header .button{
        display: flex;
    }
    header .m {
        display: none;
        height: 100vh;
    }

    header .m.open {
        display: block;
        background-color: #fff;
        position: absolute;
        width: 100%;
        left: 0;
        top: 63px;
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
    }

    header .m.open li {
        display: block;
        text-align: center;
    }

    header .m.open li a {
        display: block;
        padding: 0px;
        border-bottom: 1px solid #ddd;
    }

    header .m.open li a:hover {
        background-color: #2480cc;
        color: #fff;
    }
}